import { iconWhatsappWhiteBorder } from "../../../assets/images/icons";

const URL_WHATSAPP = "https://api.whatsapp.com/send?phone=5511945867580&text=Quero%20vender%20mais%20e%20atender%20melhor%20no%20meu%20neg%C3%B3cio";
export const WhatsAppButton = () => {
  return (
    <a
      href={URL_WHATSAPP}
      target="_blank"
      rel="noopener noreferrer"
      id="floating_btn_whatsapp"
    >
      <img
        src={iconWhatsappWhiteBorder}
        alt="WhatsApp"
      />
    </a>
  );
};
